var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"row middle-xs"},[_c('div',{staticClass:"col-xs-6"},[_c('p',[_vm._v("\n      Isi form di bawah dan customer representative kami akan menghubungi Anda\n      melalui email dalam 1x24 jam (hari kerja)\n    ")]),_vm._v(" "),_c('div',{staticClass:"mt-l"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(obs){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('validation-provider',{attrs:{"name":"nama anda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"input",class:{ error: invalid && dirty, disabled: _vm.loading }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"disabled":_vm.loading,"name":"nama","type":"text","placeholder":"nama anda"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"icon icon-account_circle input-icon-right"})]),_vm._v(" "),_c('div',{staticClass:"input-info"},[_c('transition',{attrs:{"name":"fade"}},[(invalid && dirty)?_c('span',{staticClass:"input-error"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_c('span',{staticClass:"input-hint"},[_vm._v("\n                  nama anda, misal andi\n                ")])])],1)]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"email resmi","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"input",class:{ error: invalid && dirty, disabled: _vm.loading }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"disabled":_vm.loading,"name":"email","type":"email","placeholder":"alamat email anda"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"icon icon-email input-icon-right"})]),_vm._v(" "),_c('div',{staticClass:"input-info"},[_c('transition',{attrs:{"name":"fade"}},[(invalid && dirty)?_c('span',{staticClass:"input-error"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_c('span',{staticClass:"input-hint"},[_vm._v("\n                  alamat email anda, misal andi@kantor.id\n                ")])])],1)]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"nama kantor / instansi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"input",class:{ error: invalid && dirty, disabled: _vm.loading }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.office),expression:"office"}],attrs:{"disabled":_vm.loading,"name":"kantor","type":"text","placeholder":"nama instansi anda"},domProps:{"value":(_vm.office)},on:{"input":function($event){if($event.target.composing){ return; }_vm.office=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"icon icon-work input-icon-right"})]),_vm._v(" "),_c('div',{staticClass:"input-info"},[_c('transition',{attrs:{"name":"fade"}},[(invalid && dirty)?_c('span',{staticClass:"input-error"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_c('span',{staticClass:"input-hint"},[_vm._v("\n                  nama instansi, departemen atau kantor anda, misal klinik\n                  suka sehat\n                ")])])],1)]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"btn mt-l",class:{ disabled: !obs.valid || _vm.loading },attrs:{"disabled":!obs.valid || _vm.loading,"type":"submit"}},[_vm._v("\n            saya ingin mendapatkan info lebih lanjut\n            "),_c('span',{staticClass:"icon icon-send btn-icon-right"})])],1)]}}])}),_vm._v(" "),_c('transition',{attrs:{"name":"pop-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showToast),expression:"showToast"}],staticClass:"toast-container"},[_c('div',{staticClass:"toast",class:{
              'toast-error': !_vm.isSubmissionSuccess
            },on:{"click":function($event){$event.stopPropagation();return _vm.hideMsg()}}},[_vm._v("\n            "+_vm._s(_vm.submissionResultMessage)+"\n          ")])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }