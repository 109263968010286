import { Vue, Component } from 'vue-property-decorator';
import { firestore } from 'firebase';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { delay } from 'bluebird';
import { required, email } from 'vee-validate/dist/rules';

extend('email', {
  ...email,
  message: 'format email tidak valid'
});

extend('required', {
  ...required,
  message: '{_field_} wajib diisi'
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class ContactSection extends Vue {
  name: string = '';
  email: string = '';
  office: string = '';
  submissionResultMessage = 'test';
  isSubmissionSuccess = false;
  showToast = false;
  loading = false;

  $fireStore: firestore.Firestore;
  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };

  async onSubmit() {
    const valid = await this.$refs.form.validate();
    if (!valid) {
      return;
    }
    this.hideMsg();
    this.loading = true;
    this.$nuxt.$loading.start();
    try {
      await Promise.race([
        this.$fireStore.collection('subscribers').add({
          name: this.name,
          email: this.email,
          office: this.office
        }),
        delay(5000).then(() => {
          throw new Error('timeout!');
        })
      ]);
      this.displaySuccessMsg('informasi anda telah kami simpan');
    } catch (err) {
      this.displayErrorMsg('maaf, gagal melakukan pendaftaran');
      this.$nuxt.$loading.fail();
    }
    // clean up
    this.$refs.form.reset();
    this.name = '';
    this.email = '';
    this.office = '';
    this.loading = false;
    this.$nuxt.$loading.finish();
  }

  displayErrorMsg(msg: string) {
    this.isSubmissionSuccess = false;
    this.submissionResultMessage = msg;
    this.showToast = true;
    setTimeout(() => {
      this.hideMsg();
    }, 5000);
  }

  displaySuccessMsg(msg: string) {
    this.isSubmissionSuccess = true;
    this.submissionResultMessage = msg;
    this.showToast = true;
    setTimeout(() => {
      this.hideMsg();
    }, 5000);
  }

  hideMsg() {
    this.showToast = false;
  }
}
