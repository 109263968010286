import MainSection from '@/components/main.vue';
import VisionSection from '@/components/vision.vue';
import FeatureSection from '@/components/features.vue';
import AdvantagesSection from '@/components/advantages.vue';
import ContactSection from '@/components/contact.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  layout: 'main',
  components: {
    MainSection,
    VisionSection,
    FeatureSection,
    AdvantagesSection,
    ContactSection
  }
})
export default class IndexPage extends Vue {}
