import { Vue, Component } from 'vue-property-decorator';

const bannerTexts = ['mudah', 'cepat', 'murah', 'handal'];

@Component
export default class MainSection extends Vue {
  currentBannerIndex = 0;
  get bannerText(): string {
    return bannerTexts[this.currentBannerIndex];
  }

  mounted() {
    setInterval(() => {
      this.nextBanner();
    }, 2000);
  }

  nextBanner() {
    if (this.currentBannerIndex < bannerTexts.length - 1) {
      this.currentBannerIndex++;
      return;
    }
    this.currentBannerIndex = 0;
  }
}
